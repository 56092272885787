<style scoped>
.cms-inner-page{
    padding:35px 0;
}
.contact-title{
    font-size:28px;
    text-align:center;
    color:#04092c;
    margin-bottom:30px;
    font-weight:600
}
.contact-content{
    width:60%;
    margin:0 auto;
    text-align:center
}
.contact-content p,.contact-content ul li{
    font-family: 'Heebo', sans-serif;
    color: #04092c;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0;
}
.contact-content p b{
    color: #04092c;
    letter-spacing: .5px;
}
.contact-content ul{
    padding-left: 0px;
}
.contact-content ul li b{
    color:#04092c;
    letter-spacing:.5px
}
.contact-content ul li a{
    margin-left:5px;
    color:#ea3708 !important;
    font-weight:700
}
.innerpage-banner{
    height:150px;
    position: relative;
}
.innerpage-banner:after{
    content:'';
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    display:inline-block;
    background-color:rgb(0 0 0/45%);
    width:100%;
    z-index:2;
}
.innerpage-banner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.innerpage-banner .section-header{
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -ms-flex:1;
    flex:1;
    height:100%;
    max-width:100%;
    position:absolute;
    width:100%;
    z-index:3;
    justify-content:center;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 10px;
}
.innerpage-banner .section-header h2{
    color:#fff;
    font-family:'Heebo', sans-serif !important;
    font-weight:700;
    font-size: 36px;
    text-align: center;
    margin-bottom: 0px;
}
.contact-page{
    height: calc(100vh - 471px);
}
@media (min-width: 1024px){
    .innerpage-banner{
    height:180px
    }
}
@media (max-width: 1280px){
    .innerpage-banner .container{
    max-width:100%
    }
}
@media (max-width: 1200px){
    .contact-page {
    height: calc(100vh - 488px);
    }
}
@media (max-width: 1024px) {
    .cms-inner-page{
    padding:50px 0
    }
    .contact-title{
    font-size:24px;
    margin-bottom:15px
    }
    .contact-content{
    width:80%
    }
    .contact-content p,.contact-content ul li{
    font-size: 14px;
    }
    .innerpage-banner .section-header h2{
    font-size:32px
    }
    .contact-page{
    height: calc(100vh - 497px);
    }
}
@media (max-width: 1023px) {
    .contact-page{
    height: calc(100vh - 467px);
    }
}
@media (max-width: 768px) {
    .contact-title{
    font-size:20px
    }
    .contact-content{
    width:100%
    }
    .contact-content p,.contact-content ul li{
    font-size: 13px;
    line-height: 20px;
    }
    .innerpage-banner .section-header h2{
    font-size:24px
    }
    .contact-page{
    height: calc(100vh - 480px);
    }
}
@media (max-width: 767px) {
    .cms-inner-page{
    padding:35px 0
    }
    .innerpage-banner .container{
    max-width:100%
    }
    .contact-page{
    height: calc(100vh - 469px);
    }
}
@media (max-width: 575px) {
    .contact-page{
    height: calc(100vh - 476px);
    }
}
@media (max-width: 480px) {
    .contact-page{
    height: calc(100vh - 476px);
    }
}
@media (max-width: 400px) {
    .contact-page{
    height: calc(100vh - 464px);
    }
}
</style>
<template>
    <section class="innerpage-banner contact-page-banner">
    <v-lazy-image src="../images/contact-banner.webp" alt="image" width="1920" height="235"></v-lazy-image>
    <div class="section-header">
        <h2>About Us</h2>
        <BannerSubText />
    </div>
    </section>
    <section class="contact-page cms-inner-page">
    <div class="container">
        <h3 class="contact-title">Live Events, Lit Prices!</h3>
        <div class="contact-content">
        <p>Lit Seats was started to offer die-hard fans of sports, music and theater great seats at great prices to thousands of events. When the excitement of going to a live event should be a fun experience, and we want to make sure the ticket buying process doesn’t detract from that experience. We make it easy for fans to buy tickets and attend events!</p>
        </div>
    </div>
    </section>
</template>

<script>
import VLazyImage from 'v-lazy-image'
import BannerSubText from './../../components/layouts/BannerSubText.vue'

export default {
  name: 'Contact',
  components: {
    VLazyImage,
    BannerSubText
  },
  beforeCreate () {
    document.title = 'About Us | ' + process.env.VUE_APP_NAME
  },
  data () {
    return {
      appName: process.env.VUE_APP_NAME,
      phoneNumberTollFree: process.env.VUE_APP_CONTACT_US_TOLL_FREE_NUMBER,
      phoneNumberInternational: process.env.VUE_APP_CONTACT_US_INTERNATIONAL_NUMBER,
      contactEmail: process.env.VUE_APP_CONTACT_US_EMAIL
    }
  }
}
</script>
